
import { Component, Vue } from 'vue-property-decorator'
import MapLocation from '@/components/mapLocation/Index.vue'
import { Info } from '@/types/ordinarySeedling'
import { FileInfo } from '@/types/common'

@Component({
  components: { MapLocation },
  filters: {
    imgListFormat (list: FileInfo[]) {
      const imgList = list.map(item => {
        return item.filePrefix + item.fileUrl
      })
      return imgList || []
    }
  }
})

export default class OrdinarySeedlingAdd extends Vue {
  private info: Info = {
    projectId: '',
    seedlingTypeName: '',
    plantName: '',
    plantDate: '',
    treeAge: '',
    standard: '',
    isFamous: '0',
    locationList: [],
    seedlingIntroduce: '',
    fileList: []
  }

  private tableData = []

  activeName = '0'
  created () {
    this.getDetail()
    this.getSedingInspectionList()
  }

  getDetail () {
    this.$axios.get(this.$apis.seedling.selectYhSeedlingBySeedlingId, {
      seedlingId: this.$route.params.seedlingId
    }).then(res => {
      this.info = res || {}
    })
  }

  getSedingInspectionList () {
    this.$axios.get(this.$apis.seedlingInspection.selectSeedlingCheckRecordHisList, {
      seedlingId: this.$route.params.seedlingId
    }).then(res => {
      this.tableData = res || {}
    })
  }
}
