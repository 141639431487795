import { render, staticRenderFns } from "./PlantDetail.vue?vue&type=template&id=1622f63c&scoped=true&"
import script from "./PlantDetail.vue?vue&type=script&lang=ts&"
export * from "./PlantDetail.vue?vue&type=script&lang=ts&"
import style0 from "./PlantDetail.vue?vue&type=style&index=0&id=1622f63c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1622f63c",
  null
  
)

export default component.exports